import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import { resolveYear } from '../utils.js';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import TopSellerSection from './dubai/basic/topSellersSection.js';
import SqmPriceSection from './dubai/basic/sqmPriceSection.js';
import TransactionCalendarSection from './dubai/basic/transactionCalendarSection.js';
import BasicSection from './dubai/basic/basicSection.js';
import NewProjectsSection from './dubai/projects/newProjectsSection.js';
import DelayedProjectsSection from './dubai/projects/delayedProjectsSection.js';
import UpcomingProjectsSection from './dubai/projects/upcomingProjectsSection.js';

import PriceDistributionSection from './dubai/analytics/priceDistributionSection.js';
import ResaleSection from './dubai/analytics/ResaleSection.js';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';


import CustomizedTooltips from "./common/tooltip.js";
import ScrollToTop from './common/ScrollToTop.js';
import Divider from '@mui/material/Divider';


export default function DistrictStandalone() {

  const { districtId } = useParams();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [year, setYear] = React.useState({
    start: 2023,
    end: 2025,
    key: 'this_year'
  });

  const [propType, setPropType] = React.useState({
    filter: 'fv',
    key: 'all'
  });

  const [regType, setRegType] = React.useState({
    filter: 'or',
    key: 'all'
  });

  const [roomsType, setRoomsType] = React.useState({
    filter: 'all',
    key: 'all'
  });

  const resolveFilters = (ignore) => {
    let countFilters = 0;
    let applied = []
    if (propType.key !== 'all' && !ignore?.includes('propType')) {
      countFilters = countFilters + 1;
      applied.push('Property types')
    }
    if (regType.key !== 'all' && !ignore?.includes('regType')) {
      countFilters = countFilters + 1;
      applied.push('Registration types')
    }
    if (roomsType.key !== 'all' && !ignore?.includes('roomsType')) {
      countFilters = countFilters + 1;
      applied.push('Unit types')
    }
    return {
      value: countFilters > 0 ? `+${countFilters}` : 0,
      filters: applied.join(', ')
    }
  }

  return (
    <>
      <NewMenu />
      <Menu />
      <ScrollToTop />
      <Box container sx={{ pt: 13, marginLeft: {xs: 0, sm: '95px'}}}>
      <Container component="main" sx={{padding: {xs: 1} }}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={12} container>

            <Grid2 xs={12} md={12} pb={3}>
              <Typography variant="h1" sx={{
                fontSize: '2rem',
                pb: 1,
              }}>
                {t('dubai.title')} {resolveYear(year)}
              </Typography>
              <Typography variant="content" sx={{
                pb: 3,
              }}>
                {t('dubai.description')}
              </Typography>
            </Grid2>

            <Grid2 xs={7} lg={9} pb={0}>
              <Typography variant="h1">
                {t('dubai.basicSection.title')} {resolveYear(year)}
                <Tooltip title={`${resolveFilters('propType').filters} filter applied.`}>
                  <Badge badgeContent={resolveFilters('propType').value} color="info" style={{ transform: 'translate(11px, -10px)' }}></Badge>
                </Tooltip>
              </Typography>
              <Typography variant="subtitle1" mb={1}>{t('dubai.basicSection.subTitle')}<CustomizedTooltips title={t('dubai.basicSection.tooltip')} /></Typography>
            </Grid2>
            <Grid2 xs={12} md={12} pt={0}>
              <Divider flexItem />
            </Grid2>
          </Grid2>
          <Grid2 xs={12} md={12}>
            {/* <Typography component="h1" variant="h1">
              {districtId}
            </Typography> */}
            <Grid2 xs={12} md={12} container spacing={1}>
              <BasicSection year={year} roomsType={roomsType} resolveFilters={resolveFilters} regType={regType} />
              <SqmPriceSection user={user} year={year} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
            </Grid2>

            <Grid2 container spacing={2} pt={3}>
              <NewProjectsSection />
              <UpcomingProjectsSection />
              <DelayedProjectsSection />
            </Grid2>

            <Grid2 xs={12} md={12} container spacing={1}>
              <TransactionCalendarSection year={year} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
              <TopSellerSection year={year} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
            </Grid2>

            <Grid2 container spacing={2}>
              <Grid2 xs={7} md={9}>
                <Typography variant="h1">
                  {t('dubai.analyticsSection.distribution.title')} {resolveYear(year)}
                  <Tooltip title={`${resolveFilters().filters} filters applied.`}>
                    <Badge badgeContent={resolveFilters().value} color="info" style={{ transform: 'translate(11px, -10px)' }}></Badge>
                  </Tooltip>
                </Typography>
                <Typography variant="subtitle1" mb={1}>{t('dubai.analyticsSection.distribution.subTitle')}
                  <CustomizedTooltips title={t('dubai.analyticsSection.distribution.tooltip')} />
                </Typography>
              </Grid2>
              <Grid2 xs={12} md={12}>
                <Divider flexItem />
              </Grid2>
            </Grid2>
            <PriceDistributionSection
              // selectProject={selectProject} 
              year={year} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
            {/* <ResaleSection user={user} dbUser={dbUser} selectedId={districtId}
            // selectProject={selectProject} 
            /> */}
          </Grid2>
        </Grid2>
      </Container>
      </Box>
    </>
  );
}
